<template>
  <div class="container">
    <a-range-picker v-model:value="value2"  @change = test()  :disabledDate="disabledDate" />
    <div id="main" class="chart"></div>
  </div>
</template>

<script setup>
import {onMounted, ref, computed, watch} from 'vue';
import * as echarts from 'echarts';
import axios from 'axios';
import dayjs from 'dayjs';


import { message } from 'ant-design-vue';

const initStartTime = ref(dayjs().subtract(1, 'day').startOf('day')); // 昨天的开始时间
const initSndTime = ref(dayjs().startOf('day')); // 今天的开始时间


console.log("转换后的")
const startTimeISO = initStartTime.value.add(1,"day").toISOString();
const endTimeISO = initSndTime.value.add(1,"day").toISOString();
console.log(startTimeISO)
console.log(endTimeISO)
const value2 = ref([initStartTime.value, initSndTime.value]);
getCommandNumber(startTimeISO,endTimeISO)

let startTime = ref();
let endTime = ref();
let myChart = ref();

function test() {
  const startTime = dayjs(value2.value[0]);

  const endTime = dayjs(value2.value[1]);
  console.log(endTime)
 const newStart =  startTime.add(1,"day")
  const newEnd = endTime.add(1,"day")
  console.log(JSON.stringify(newEnd.toISOString(), null, 2));
  getCommandNumber(JSON.stringify(newStart.toISOString(), null, 2),JSON.stringify(newEnd.toISOString(), null, 2));
}




function getCommandNumber(startTime,endTime){
  console.log("接收到的开始时间为"+startTime)
  console.log("接受到的结束时间为"+endTime)

  const formatDate = dateString => {
    // 使用正则表达式提取日期部分
    const match = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
    // 检查是否提取成功
    if (match) {
      const year = match[1];
      const month = match[2].padStart(2, '0'); // 确保月份是两位数
      const day = match[3].padStart(2, '0'); // 确保日期是两位数
      return `${year}-${month}-${day}`; // 返回 YYYY-MM-DD 格式
    } else {
      console.error('无效的日期格式:', dateString);
      return ''; // 返回空字符串或其他适当的默认值
    }
  };
  console.log("转换后的开始时间"+formatDate(startTime))
  axios.get('/api/',{
    params:{
      startTime:formatDate(startTime),
      endTime:formatDate(endTime),
    }
  })
      .then(response => {
        if (response.data.code  === 200) {
          console.log("获取到的数据为:");
          console.log(response.data.data);
          console.log("正在传输数据")
          updateChartOptions(response.data.data)
        }else {
          throw new  Error("error")
        }
      })
      .catch(error => {
        message.error("请求失败，可能是因为超过了每分钟次数限制，具体请联系管理员",error)
      });
}





const updateChartOptions = (commands) => {
  console.log("图标接受到的数据为：")
  console.log(commands)
  // 初始化 xAxis 和 series 数据
  const xAxisData = commands.map(cmd => cmd.command);
  const seriesData = commands.map(cmd => cmd.num);
  console.log("xAxisData:"+xAxisData)
  // 更新图表的选项
// 确保 myChart.value 已经是 ECharts 实例

    myChart.setOption({
      title: {
        text: '命令执行次数'
      },
      legend: {},
      tooltip: {},
      xAxis: { type: 'category', data: xAxisData },
      yAxis: {},
      series: [{ type: 'bar', data: seriesData ,name: '执行次数'}]
    });



}

function disabledDate(current) {
  if (!current) return false;

  const currentDayjs = dayjs(current);

  const today = dayjs().startOf('day');
  const oneMonthAgo = dayjs().subtract(1, 'month').startOf('day');


  return currentDayjs.isBefore(oneMonthAgo, 'day') || currentDayjs.isAfter(today, 'day');
}


onMounted(() => {
   myChart = echarts.init(document.getElementById('main'));
  // 绘制图表
  myChart.setOption({
    title: {
      text: 'ECharts 入门示例'
    },
    tooltip: {},
    xAxis: {
      data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
    },
    yAxis: {},
    series: [
      {
        name: '销量',
        type: 'bar',
        data: [5, 20, 36, 10, 10, 20]
      }
    ]
  });
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.chart {
  width: 600px;
  height: 400px;
}
</style>
